<template>
  <div v-if="showTagSelector">
    <tag-selector v-if="activeTag" :active-tag="activeTag" :is-overview="true" />
    <v-chip v-else-if="!activeTag && hasAnyActiveTag" class="invisible-chip"></v-chip>
  </div>
</template>

<script>
import TagSelector from "@/components/ui/tagselector/TagSelector.vue";
import { mapGetters } from "vuex";

export default {
  name: "OverviewTagSelector",
  props: { puId: { type: String, required: true } },
  components: { TagSelector },
  data() {
    return {
      activeTag: null,
    };
  },
  computed: {
    ...mapGetters("overview", ["activeProductionUnitsState", "hasAnyActiveTag"]),
    ...mapGetters("navigation", ["factoryTags", "isLiveData"]),
    showTagSelector() {
      return this.factoryTags.length > 0 && this.isLiveData;
    },
  },
  mounted() {
    this.updateActiveTag();
  },
  methods: {
    updateActiveTag() {
      if (this.showTagSelector) {
        const currentProductionUnit = this.activeProductionUnitsState.find((pu) => pu.puId === this.puId);
        this.activeTag =
          currentProductionUnit && currentProductionUnit.tags.length > 0 ? currentProductionUnit.tags[0] : null;
      }
    },
  },
  watch: {
    activeProductionUnitsState() {
      this.updateActiveTag();
    },
  },
};
</script>

<style scoped>
.invisible-chip {
  visibility: hidden;
}
</style>
