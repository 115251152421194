export class OverviewKpis {
  static AVAILABILITY = "availability";
  static UPTIME = "total-uptime";
  static PLANNED_UNPLANNED_DOWNTIME = "total-downtime";
  static PERFORMANCE = "performance";
  static RATE_PER_HOUR = "product-throughput";
  static RATE_PER_MINUTE = "product-throughput-per-minute";
  static RATE_PER_SECOND = "product-throughput-per-second";
  static SPEED5MINUTES_PER_HOUR = "product-speed-5m";
  static SPEED5MINUTES_PER_MINUTE = "product-speed-5m-per-minute";
  static SPEED5MINUTES_PER_SECOND = "product-speed-5m-per-second";
  static QUALITY = "quality";
  static NET_REJECT_QUANTITY = "reject-quantity";
  static OEE = "oee";
  static OOE = "ooe";
  static TIME_TO_COMPLETION = "time-to-completion";
  static COMPLETION_PERCENTAGE = "production";
  static CURRENT_PRODUCT_QUANTITY = "current-product-quantity";
  static ALL_PRODUCT_QUANTITY = "all-products-quantity";
  static TOTAL_WEIGHT = "total-weight";
  static TOTAL_VOLUME = "total-volume";
  static TOTAL_LENGTH = "total-length";
  static GIVEAWAY_PERCENTAGE = "product-giveaway-percent";
  static GIVEAWAY = "product-giveaway";
  static AVERAGE_WEIGHT_LENGTH_VOLUME = "product-average-giveaway";
}
