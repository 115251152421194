<template>
  <article class="pu-details-timeline wx-tile-panel">
    <h3 v-if="!isXs" class="mb-6 text-center">
      {{ $t("overview.details.time.title") }}
      <wx-contextualized-help :tooltip-text="$t('overview.details.time.total.downTooltip')" />
    </h3>
    <div class="pu-time-container">
      <wx-chart-doughnut-graph
        ref="doughnut"
        class="canvas-container"
        :chart-data="chartData"
        :chart-options="chartOptions"
      />
      <div class="time-data-list mt-4">
        <dl class="data-inline-grid">
          <dt class="label">
            {{ $t("overview.details.time.total.up") }}
          </dt>
          <dd class="data text-right">{{ uptimeAsString }}</dd>
          <dt class="mt-2 label">
            {{ $t("overview.details.time.total.down") }}
          </dt>
          <dd class="mt-2 data text-right">{{ totalDowntimeAsString }}</dd>
        </dl>
      </div>
    </div>
    <!-- <v-icon color="var(--color-subtle-text-theme)" class="mr-1">mdi-clock-time-five</v-icon> -->
    <!-- <span class="wx-subtle-text mr-1">{{ $t("overview.details.time.startTime") }}</span> -->
    <!-- {{ productionUnitTimeDistribution.dataSince }} -->
  </article>
</template>

<script>
import StyleValues from "@/styles/_values.scss";
import WxChartDoughnutGraph from "@/components/ui/WxChartDoughnutGraph";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp";
import { Duration } from "luxon";
import { mapGetters } from "vuex";

export default {
  name: "PUDetailsTime",
  components: {
    WxContextualizedHelp,
    WxChartDoughnutGraph,
  },
  watch: {
    activeTheme() {
      this.$refs.doughnut.options.legend.labels.fontColor =
        this.theme === "light" ? StyleValues.color_text_themeLight : StyleValues.color_text_themeDark;
      this.$refs.doughnut.renderChart(this.chartData, this.$refs.doughnut.options);
    },
  },
  computed: {
    ...mapGetters("overview", ["productionUnitTimeDistribution"]),
    ...mapGetters("user", ["theme", "isAccessible"]),
    activeTheme() {
      return this.theme;
    },
    chartOptions() {
      return {
        cutoutPercentage: 70,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
        plugins: {
          legend: {
            display: true,
            position: "right",
            labels: {
              color: this.theme === "light" ? StyleValues.color_text_themeLight : StyleValues.color_text_themeDark,
              boxWidth: 12,
            },
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            callbacks: {
              title: (tooltipItems) => {
                return tooltipItems[0].label;
              },
              label: (tooltipItem) => {
                const currentDataset = tooltipItem.dataset.data;
                let totalTimeVisibleOnChart = 0;
                for (let i = 0; i < currentDataset.length; i++) {
                  totalTimeVisibleOnChart += currentDataset[i];
                }
                const timeOfTheSelectedItem = currentDataset[tooltipItem.dataIndex];
                const percent = Math.round((100.0 * timeOfTheSelectedItem) / totalTimeVisibleOnChart);
                return this.chartValuesHMm[tooltipItem.dataIndex] + " - " + percent + "%";
              },
            },
          },
          annotation: {
            annotations: [],
          },
        },
      };
    },
    uptimeAsString() {
      if (this.productionUnitTimeDistribution.rawTimings) {
        const uptime = this.productionUnitTimeDistribution.rawTimings.uptime;
        const totalAvailableTime =
          uptime +
          this.productionUnitTimeDistribution.rawTimings.downtime_planned +
          this.productionUnitTimeDistribution.rawTimings.downtime_unplanned +
          this.productionUnitTimeDistribution.rawTimings.downtime_unjustified;
        const duration = Duration.fromMillis(uptime).toFormat("hh:mm:ss");
        if (totalAvailableTime > 0) {
          const uptimePercent = Math.round(100 * (uptime / totalAvailableTime));
          return duration + " (" + uptimePercent + "%)";
        } else {
          return duration + " (—%)";
        }
      }
      return "-";
    },
    totalDowntimeAsString() {
      if (this.productionUnitTimeDistribution.rawTimings) {
        const uptime = this.productionUnitTimeDistribution.rawTimings.uptime;
        const downtimePlanned = this.productionUnitTimeDistribution.rawTimings.downtime_planned;
        const downtimeUnplanned = this.productionUnitTimeDistribution.rawTimings.downtime_unplanned;
        const downtimeUnjustified = this.productionUnitTimeDistribution.rawTimings.downtime_unjustified;
        const totalDowntime = downtimePlanned + downtimeUnplanned + downtimeUnjustified;
        const totalAvailableTime = uptime + downtimePlanned + downtimeUnplanned + downtimeUnjustified;
        const duration = Duration.fromMillis(totalDowntime).toFormat("hh:mm:ss");
        if (totalAvailableTime > 0) {
          const uptimePercent = Math.round(100 * (uptime / totalAvailableTime));
          const downPercent = 100.0 - uptimePercent;
          return duration + " (" + downPercent + "%)";
        } else {
          return duration + " (—%)";
        }
      }
      return "-";
    },
    chartValuesInSeconds() {
      let valuesInSeconds = [];
      this.productionUnitTimeDistribution.rawChartData.forEach((el) => valuesInSeconds.push(el.seconds));
      return valuesInSeconds;
    },
    chartValuesHMm() {
      let stringArray = [];
      this.chartValuesInSeconds.forEach((el) => stringArray.push(Duration.fromMillis(el * 1000).toFormat("h:mm:ss")));
      return stringArray;
    },
    chartColors() {
      let colors = [];
      const styles = getComputedStyle(document.documentElement);
      this.productionUnitTimeDistribution.rawChartData.forEach((el) => {
        let colorSuffix = this.isAccessible ? "Accessible" : "";
        switch (el.type) {
          case "active": {
            colors.push(styles.getPropertyValue(`--color-uptime${colorSuffix}`));
            break;
          }
          case "planned-downtime":
            colors.push(styles.getPropertyValue(`--color-plannedDowntime${colorSuffix}`));
            break;
          case "unjustified-downtime":
            colors.push(styles.getPropertyValue(`--color-unjustifiedDowntime${colorSuffix}`));
            break;
          case "justified-downtime":
            colors.push(styles.getPropertyValue(`--color-justifiedDowntime${colorSuffix}`));
            break;
          case "disconnected":
            colors.push(styles.getPropertyValue(`--color-disconnected${colorSuffix}`));
            break;
          case "unknown":
            colors.push(styles.getPropertyValue(`--color-unknown${colorSuffix}`));
            break;
          case "out-of-production": {
            colors.push(styles.getPropertyValue(`--color-outOfProduction${colorSuffix}`));
            break;
          }
          default:
            console.warn("Missing color for graph " + el.type);
        }
      });
      return colors;
    },
    chartLabels() {
      let labels = [];
      this.productionUnitTimeDistribution.rawChartData.forEach((el) => {
        switch (el.type) {
          case "active":
            labels.push(this.$t("legend.active"));
            break;
          case "planned-downtime":
            labels.push(this.$t("legend.plannedDowntime"));
            break;
          case "unjustified-downtime":
            labels.push(this.$t("legend.unjustifiedDowntime"));
            break;
          case "justified-downtime":
            labels.push(this.$t("legend.justifiedDowntime"));
            break;
          case "disconnected":
            labels.push(this.$t("legend.disconnection"));
            break;
          case "unknown":
            labels.push(this.$t("legend.unknown"));
            break;
          case "out-of-production":
            labels.push(this.$t("legend.outOfProduction"));
            break;
          default:
            console.warn("Missing translation for graph " + el.type);
        }
      });
      return labels;
    },
    chartData() {
      return {
        datasets: [
          {
            data: this.chartValuesInSeconds,
            backgroundColor: this.chartColors,
            hoverBackgroundColor: this.chartColors,
            borderWidth: 0,
          },
        ],
        labels: this.chartLabels,
      };
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
  width: 100%;
  height: 200px;
}

::v-deep canvas {
  width: 320px;
  height: 200px;
  max-width: 400px;
  margin: 0 auto;
}

.wx-tile-panel {
  .pu-time-container {
    padding-bottom: var(--box-padding-dashboard);
  }

  @media ($wx-sm-min) {
    .pu-time-container {
      padding-bottom: 0;
    }
  }
}

/**
 * Under the graph
 *
 * the `.data small` markup does not exist yet.
 * it requires to parse html `<small>`
 * within the {{ totalDowntimeAsString }}
 * in
 */
.time-data-list {
  max-width: 18rem;
  margin-inline: auto;
  background-color: var(--color-element-layer2);

  .label,
  .data small {
    color: var(--color-text-subtle-theme);
  }
  .data small {
    font-size: var(--font-size-sm);
  }
}
</style>
