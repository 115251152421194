<template>
  <fragment>
    <div class="overviewTitle">
      <h2 class="mb-6">{{ $t("overview.productionUnit.title") }}</h2>
      <div class="PU-list">
        <template v-for="pu in activeFactoryProductionUnits">
          <wx-skeleton-loader type="image" :key="pu.id" />
        </template>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import WxSkeletonLoader from "@/components/ui/WxSkeletonLoader.vue";

export default {
  name: "PUListLoader",
  components: { WxSkeletonLoader },
  computed: {
    ...mapGetters("navigation", ["activeFactoryProductionUnits"]),
  },
};
</script>

<style lang="scss" scoped>
.overviewTitle {
  padding-top: 20px;
}
.PU-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--grid-gutter);
  grid-auto-flow: row dense;
  padding-left: 7px; // spacer for shadow from elevation props
  padding-bottom: var(--grid-gutter);

  &.clicked {
    border: 2px solid red;
  }
  @media ($wx-sm-min) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(var(--grid-gutter) / 2);
  }

  @media ($wx-md-min) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ($wx-lg-min) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media ($wx-xl-min) {
    grid-template-columns: repeat(6, 1fr);
  }

  /**
  * Dimming the visibility of the unactive
  * siblings of a `pu-cards.details-active`
  * from PUItems.vue
  */
  article {
    opacity: 1;
    transition: var(--smooth-transition);
  }

  &.child-clicked {
    article:not(.disconnected) {
      // all cards that are not clicked
      &:not(.details-active) {
        opacity: var(--opacity-disabled-effect);
      }
      // Un mouse hover users can better read the unactivated card
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
</style>