<!--
Identified <span> tags are wrappers to get
"text-overflow: ellipsis" to work with a fluid width.
-->
<template>
  <v-card
    :wxid="$options.name"
    :title="getProductionUnitHoverTitle"
    tag="article"
    class="pu-card"
    :class="[getCardCssClass(puStatus), detailsActive ? 'details-active' : false]"
    @click="goToDashboard"
  >
    <overview-tag-selector :pu-id="puId" class="mb-2" />

    <section :class="{ 'is-presenter': isPresenter, 'is-fullscreen': isFullScreen }" class="card-body">
      <h3 class="ellipsis mb-1 wx-typo-h2">
        <!-- span for text-overflow: ellipsis -->
        <span>{{ puName }}</span>
      </h3>

      <template v-if="isDisconnected">
        <p class="ellipsis pu-subheader wx-typo-h3">
          <!-- span for text-overflow: ellipsis -->
          <span>{{ $t("overview.productionUnit.disconnected") }}</span>
        </p>
        <div class="wx-typo-h1">{{ disconnectedDuration }}</div>
        <div class="mt-3 wx-subtle-text wx-typo-h3">{{ $t("overview.productionUnit.disconnectedLastMeasure") }}:</div>
        <div class="wx-subtle-text wx-typo-h3">{{ disconnectedStartDate }}</div>
      </template>

      <template v-else>
        <p class="ellipsis pu-subheader wx-typo-h3">
          <!-- span for text-overflow: ellipsis -->
          <span class="wx-subtle-text">{{ getProductSkuAndName }}</span>
        </p>
        <div v-if="!hasDoubleKpiValues">
          <div class="kpi-circle" :class="kpiCircleClass">
            <div class="kpi-value wx-typo-h1"> {{ getKpiValue }}<span class="wx-typo-h3" v-if="oeeValue"></span> </div>
            <div class="kpi-label wx-subtle-text wx-typo-h2">{{ getKpiTarget }}</div>
          </div>
        </div>
        <div v-else>
          <div class="kpi-value wx-typo-h3 text-center">{{ getKpiName[0] }}</div>
          <div class="kpi-value wx-typo-h1 text-center mb-1">
            {{ getKpiValue[0] }}<span class="wx-typo-h3 b" v-if="oeeValue"></span>
          </div>
          <div class="kpi-value wx-typo-h3 text-center">{{ getKpiName[1] }}</div>
          <div class="kpi-value wx-typo-h1 text-center">
            {{ getKpiValue[1] }}<span class="wx-typo-h3" v-if="oeeValue"></span>
          </div>
        </div>
        <div class="kpi-name wx-typo-h3">{{ bottomLabel }}</div>
      </template>
    </section>
    <footer v-if="!isPresenter" class="card-footer">
      <wx-btn-standard
        @click.stop="toggleDetails(index)"
        :title="$t('overview.productionUnit.buttonHoverTitle')"
        :class="detailsActive ? 'clicked' : 'unclicked'"
        class="mt-3 btn-pu-details-control"
        outlined
        block
      >
        <v-icon class="detailsBtnIcon" :color="$vuetify.theme.dark ? 'white' : 'black'">mdi-chevron-down</v-icon>
      </wx-btn-standard>
    </footer>
    <tile-disconnected-dialog v-model="dialogState" @closeDialog="closeDialog" />
  </v-card>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import { mapActions, mapGetters } from "vuex";
import RouteService from "@/router/RouteService";
import TileDisconnectedDialog from "@/components/dashboard/tiles/TileDisconnectedDialog";
import * as TimeUtils from "@/store/TimeUtils";
import { Duration } from "luxon";
import { DATE_TIME_FORMAT_NO_TIMEZONE } from "@/store/TimeUtils";
import Helpers, { dash } from "@/helpers";
import Tiles from "@/components/Tiles";
import PackageFeatures from "@/components/PackageFeatures";
import OverviewTagSelector from "@/components/ui/tagselector/OverviewTagSelector.vue";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import { convertKilogramTo, convertLiterTo, convertMeterTo, getUnitLabel } from "@/UnitUtils";
import { OverviewKpis } from "@/components/overview/OverviewKpis";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";
import { getUnitType, validProductUnits } from "@/components/user/UserPreferencesService";
import Accessibility from "@/components/Accessibility";

const AVAILABILITY = OverviewKpis.AVAILABILITY;
const UPTIME = OverviewKpis.UPTIME;
const PLANNED_UNPLANNED_DOWNTIME = OverviewKpis.PLANNED_UNPLANNED_DOWNTIME;
const PERFORMANCE = OverviewKpis.PERFORMANCE;
const RATE_PER_HOUR = OverviewKpis.RATE_PER_HOUR;
const RATE_PER_MINUTE = OverviewKpis.RATE_PER_MINUTE;
const RATE_PER_SECOND = OverviewKpis.RATE_PER_SECOND;
const SPEED5MINUTES_PER_HOUR = OverviewKpis.SPEED5MINUTES_PER_HOUR;
const SPEED5MINUTES_PER_MINUTE = OverviewKpis.SPEED5MINUTES_PER_MINUTE;
const SPEED5MINUTES_PER_SECOND = OverviewKpis.SPEED5MINUTES_PER_SECOND;
const QUALITY = OverviewKpis.QUALITY;
const NET_REJECT_QUANTITY = OverviewKpis.NET_REJECT_QUANTITY;
const OEE = OverviewKpis.OEE;
const OOE = OverviewKpis.OOE;
const TIME_TO_COMPLETION = OverviewKpis.TIME_TO_COMPLETION;
const COMPLETION_PERCENTAGE = OverviewKpis.COMPLETION_PERCENTAGE;
const CURRENT_PRODUCT_QUANTITY = OverviewKpis.CURRENT_PRODUCT_QUANTITY;
const ALL_PRODUCT_QUANTITY = OverviewKpis.ALL_PRODUCT_QUANTITY;
const TOTAL_WEIGHT = OverviewKpis.TOTAL_WEIGHT;
const TOTAL_VOLUME = OverviewKpis.TOTAL_VOLUME;
const TOTAL_LENGTH = OverviewKpis.TOTAL_LENGTH;
const GIVEAWAY_PERCENTAGE = OverviewKpis.GIVEAWAY_PERCENTAGE;
const GIVEAWAY = OverviewKpis.GIVEAWAY;
const AVERAGE_WEIGHT_LENGTH_VOLUME = OverviewKpis.AVERAGE_WEIGHT_LENGTH_VOLUME;

export default {
  name: "PUItem",
  components: { OverviewTagSelector, WxBtnStandard, TileDisconnectedDialog },
  props: {
    puId: {
      type: String,
      required: true,
    },
    puName: {
      type: String,
      required: true,
    },
    puStatus: {
      type: String,
      required: true,
    },
    productSku: {
      type: String,
      required: false,
      default: null,
    },
    productName: {
      type: String,
      required: false,
      default: null,
    },
    oeeValue: {
      type: Number,
      required: false,
      default: null,
    },
    oeeTarget: {
      type: Number,
      required: false,
      default: null,
    },
    ooeValue: {
      type: Number,
      required: false,
      default: null,
    },
    ooeTarget: {
      type: Number,
      required: false,
      default: null,
    },
    availabilityValue: {
      type: Number,
      required: false,
      default: null,
    },
    availabilityTarget: {
      type: Number,
      required: false,
      default: null,
    },
    performanceValue: {
      type: Number,
      required: false,
      default: null,
    },
    performanceTarget: {
      type: Number,
      required: false,
      default: null,
    },
    qualityValue: {
      type: Number,
      required: false,
      default: null,
    },
    qualityTarget: {
      type: Number,
      required: false,
      default: null,
    },
    allProductQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    currentProductQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerHourValue: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerHourTarget: {
      type: Number,
      required: false,
      default: null,
    },
    wlvRatePerHour: {
      type: Number,
      required: false,
      default: null,
    },
    wlvRatePerHourTarget: {
      type: Number,
      required: false,
      default: null,
    },
    wlvRateUnit: {
      type: String,
      required: false,
      default: null,
    },
    ratePerMinuteValue: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerMinuteTarget: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerSecondValue: {
      type: Number,
      required: false,
      default: null,
    },
    ratePerSecondTarget: {
      type: Number,
      required: false,
      default: null,
    },
    wlvRatePerMinute: {
      type: Number,
      required: false,
      default: null,
    },
    wlvRatePerMinuteTarget: {
      type: Number,
      required: false,
      default: null,
    },
    wlvRatePerSecond: {
      type: Number,
      required: false,
      default: null,
    },
    wlvRatePerSecondTarget: {
      type: Number,
      required: false,
      default: null,
    },
    speedFiveMinuteValue: {
      type: Number,
      required: false,
      default: null,
    },
    speedFiveMinuteValuePerMinute: {
      type: Number,
      required: false,
      default: null,
    },
    speedFiveMinuteValuePerSecond: {
      type: Number,
      required: false,
      default: null,
    },
    uptimeValue: {
      type: Number,
      required: false,
      default: null,
    },
    plannedDowntimeValue: {
      type: Number,
      required: false,
      default: null,
    },
    unplannedDowntimeValue: {
      type: Number,
      required: false,
      default: null,
    },
    netQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    rejectQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    timeToCompletionValue: {
      type: String,
      required: false,
      default: null,
    },
    completionPercentageValue: {
      type: Number,
      required: false,
      default: null,
    },
    completionNetQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    completionPlannedQuantityValue: {
      type: Number,
      required: false,
      default: null,
    },
    totalWeightValue: {
      type: Number,
      required: false,
      default: null,
    },
    totalWeightStandardUnit: {
      type: String,
      required: false,
      default: null,
    },
    totalRejectedWeightValue: {
      type: Number,
      required: false,
      default: null,
    },
    totalVolumeValue: {
      type: Number,
      required: false,
      default: null,
    },
    totalVolumeStandardUnit: {
      type: String,
      required: false,
      default: null,
    },
    totalRejectedVolumeValue: {
      type: Number,
      required: false,
      default: null,
    },
    totalLengthValue: {
      type: Number,
      required: false,
      default: null,
    },
    totalLengthStandardUnit: {
      type: String,
      required: false,
      default: null,
    },
    totalRejectedLengthValue: {
      type: Number,
      required: false,
      default: null,
    },
    giveawayPercentageValue: {
      type: Number,
      required: false,
      default: null,
    },
    giveawayPercentageTarget: {
      type: Number,
      required: false,
      default: null,
    },
    currentProductGiveawayQuantityInStandardUnit: {
      type: Number,
      required: false,
      default: null,
    },
    averageWeightLengthVolumeValue: {
      type: Number,
      required: false,
      default: null,
    },
    averageWeightLengthVolumeTarget: {
      type: Number,
      required: false,
      default: null,
    },
    averageWeightLengthVolumeType: {
      type: String,
      required: false,
      default: null,
    },
    configuredUnit: {
      type: String,
      required: false,
      default: null,
    },
    standardUnit: {
      type: String,
      required: false,
      default: null,
    },
    isDisconnected: {
      type: Boolean,
      required: true,
    },
    isDisconnectedSince: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    detailsActive: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disconnectedInterval: null,
      disconnectedDuration: "00:00:00",
      disconnectedStartDate: "",
      dialogState: false,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits", "isLiveData"]),
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    ...mapGetters("user", ["isPresenter", "preferences"]),
    ...mapGetters("overview", ["activeProductionUnitsCoverage", "activeKpi", "activeKpiConfig"]),
    isActiveKpiConfigProductUnitCount() {
      return !this.activeKpiConfig?.product_unit || validProductUnits.indexOf(this.activeKpiConfig.product_unit) >= 0;
    },
    getProductionUnitHoverTitle() {
      return this.$t("overview.productionUnit.cardHoverTitle", { puName: this.puName });
    },
    kpiCircleClass() {
      const classes = { "is-fullscreen": this.isFullScreen };
      classes[this.getKpiStatus] = true;
      return classes;
    },
    getProductSkuAndName() {
      if (this.productSku == null) {
        return this.$t("overview.productionUnit.noProduct");
      } else {
        let name = this.productName ? " / " + this.productName : "";
        return this.productSku + name;
      }
    },
    hasDoubleKpiValues() {
      return this.activeKpi === PLANNED_UNPLANNED_DOWNTIME || this.activeKpi === NET_REJECT_QUANTITY;
    },
    getKpiValue() {
      // Value in PU card
      if (this.activeKpi === OEE) {
        return Helpers.getRoundedPercentageOrDash(this.oeeValue, true);
      } else if (this.activeKpi === AVAILABILITY) {
        return Helpers.getRoundedPercentageOrDash(this.availabilityValue, true);
      } else if (this.activeKpi === PERFORMANCE) {
        return Helpers.getRoundedPercentageOrDash(this.performanceValue, true);
      } else if (this.activeKpi === QUALITY) {
        return Helpers.getRoundedPercentageOrDash(this.qualityValue, true);
      } else if (this.activeKpi === ALL_PRODUCT_QUANTITY) {
        return Helpers.getRoundedValueOrDash(this.allProductQuantityValue, true);
      } else if (this.activeKpi === CURRENT_PRODUCT_QUANTITY) {
        return Helpers.getRoundedValueOrDash(this.currentProductQuantityValue, true);
      } else if (this.activeKpi === RATE_PER_HOUR) {
        return Helpers.getRoundedValueOrDash(
          this.isActiveKpiConfigProductUnitCount ? this.ratePerHourValue : this.getConvertedValue(this.wlvRatePerHour),
          this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughput.requiredFeature),
        );
      } else if (this.activeKpi === RATE_PER_MINUTE) {
        return Helpers.getRoundedValueOrDash(
          this.isActiveKpiConfigProductUnitCount ? this.ratePerMinuteValue : this.getConvertedValue(this.wlvRatePerMinute),
          this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
        );
      } else if (this.activeKpi === RATE_PER_SECOND) {
        return Helpers.getRoundedValueOrDash(
          this.isActiveKpiConfigProductUnitCount ? this.ratePerSecondValue : this.getConvertedValue(this.wlvRatePerSecond),
          this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerSecond.requiredFeature),
        );
      } else if (this.activeKpi === SPEED5MINUTES_PER_HOUR) {
        return Helpers.getRoundedValueOrDash(
          this.speedFiveMinuteValue,
          this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5m.requiredFeature),
        );
      } else if (this.activeKpi === SPEED5MINUTES_PER_MINUTE) {
        return Helpers.getRoundedValueOrDash(
          this.speedFiveMinuteValuePerMinute,
          this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerMinute.requiredFeature),
        );
      } else if (this.activeKpi === SPEED5MINUTES_PER_SECOND) {
        return Helpers.getRoundedValueOrDash(
          this.speedFiveMinuteValuePerSecond,
          this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerSecond.requiredFeature),
        );
      } else if (this.activeKpi === OOE) {
        return Helpers.getRoundedPercentageOrDash(
          this.ooeValue,
          this.puHasRequiredFeature(this.puId, Tiles.ooe.requiredFeature),
        );
      } else if (this.activeKpi === UPTIME) {
        return Helpers.getDurationTimeFormatOrZero(
          this.uptimeValue,
          this.puHasRequiredFeature(this.puId, Tiles.totalUptime.requiredFeature),
        );
      } else if (this.activeKpi === PLANNED_UNPLANNED_DOWNTIME) {
        return [
          Helpers.getDurationTimeFormatOrZero(
            this.unplannedDowntimeValue,
            this.puHasRequiredFeature(this.puId, Tiles.totalDowntime.requiredFeature),
          ),
          Helpers.getDurationTimeFormatOrZero(
            this.plannedDowntimeValue,
            this.puHasRequiredFeature(this.puId, Tiles.totalDowntime.requiredFeature),
          ),
        ];
      } else if (this.activeKpi === NET_REJECT_QUANTITY) {
        const unit = this.preferences.overview.product_unit;
        if (!unit) {
          return [
            Helpers.getRoundedValueOrZeroOrDash(
              this.netQuantityValue,
              this.puHasRequiredFeature(this.puId, Tiles.rejectQuantity.requiredFeature),
            ),
            Helpers.getRoundedValueOrZeroOrDash(
              this.rejectQuantityValue,
              this.puHasRequiredFeature(this.puId, Tiles.rejectQuantity.requiredFeature),
            ),
          ];
        }
        const unitType = getUnitType(unit);
        let netWlvValue = null;
        let rejectWlvValue = null;
        switch (unitType) {
          case "weight_unit":
            {
              netWlvValue = convertKilogramTo(this.totalWeightValue - this.totalRejectedWeightValue, unit);
              rejectWlvValue = convertKilogramTo(this.totalRejectedWeightValue, unit);
            }
            break;
          case "length_unit":
            {
              netWlvValue = convertMeterTo(this.totalLengthValue - this.totalRejectedLengthValue, unit);
              rejectWlvValue = convertMeterTo(this.totalRejectedLengthValue, unit);
            }
            break;
          case "volume_unit":
            {
              netWlvValue = convertLiterTo(this.totalVolumeValue - this.totalRejectedVolumeValue, unit);
              rejectWlvValue = convertLiterTo(this.totalRejectedVolumeValue, unit);
            }
            break;
        }
        return [
          Helpers.getRoundedValueOrZeroOrDash(
            netWlvValue,
            this.puHasRequiredFeature(this.puId, Tiles.rejectQuantity.requiredFeature),
          ),
          Helpers.getRoundedValueOrZeroOrDash(
            rejectWlvValue,
            this.puHasRequiredFeature(this.puId, Tiles.rejectQuantity.requiredFeature),
          ),
        ];
      } else if (this.activeKpi === TIME_TO_COMPLETION) {
        if (this.timeToCompletionValue) {
          const timeToCompletion = this.isLiveData ? this.timeToCompletionValue : dash;
          return timeToCompletion;
        } else {
          return dash;
        }
      } else if (this.activeKpi === COMPLETION_PERCENTAGE) {
        const completionPercent = this.isLiveData ?
        Helpers.getRoundedPercentageOrDash(
          this.completionPercentageValue,
          this.puHasRequiredFeature(this.puId, Tiles.production.requiredFeature),
        ) : dash;
        return completionPercent;
      } else if (this.activeKpi === TOTAL_WEIGHT) {
        if (this.isTotalWeightPreferences) {
          const targetUnit = this.preferences.overview.product_unit;
          return Helpers.getRoundedValueOrDash(
            convertKilogramTo(this.totalWeightValue, targetUnit),
            this.puHasRequiredFeature(this.puId, Tiles.totalWeight.requiredFeature),
          );
        }
        return Helpers.getRoundedValueOrDash(
          this.totalWeightValue,
          this.puHasRequiredFeature(this.puId, Tiles.totalWeight.requiredFeature),
        );
      } else if (this.activeKpi === TOTAL_VOLUME) {
        if (this.isTotalVolumePreferences) {
          const targetUnit = this.preferences.overview.product_unit;
          return Helpers.getRoundedValueOrDash(
            convertLiterTo(this.totalVolumeValue, targetUnit),
            this.puHasRequiredFeature(this.puId, Tiles.totalVolume.requiredFeature),
          );
        }
        return Helpers.getRoundedValueOrDash(
          this.totalVolumeValue,
          this.puHasRequiredFeature(this.puId, Tiles.totalVolume.requiredFeature),
        );
      } else if (this.activeKpi === TOTAL_LENGTH) {
        if (this.isTotalLengthPreferences) {
          const targetUnit = this.preferences.overview.product_unit;
          return Helpers.getRoundedValueOrDash(
            convertMeterTo(this.totalLengthValue, targetUnit),
            this.puHasRequiredFeature(this.puId, Tiles.totalLength.requiredFeature),
          );
        }
        return Helpers.getRoundedValueOrDash(
          this.totalLengthValue,
          this.puHasRequiredFeature(this.puId, Tiles.totalLength.requiredFeature),
        );
      } else if (this.activeKpi === GIVEAWAY_PERCENTAGE) {
        return Helpers.getRoundedPercentageOrDash(
          this.giveawayPercentageValue,
          this.puHasRequiredFeature(this.puId, Tiles.productGiveawayPercent.requiredFeature),
        );
      } else if (this.activeKpi === GIVEAWAY) {
        let giveawayConfiguredvalue = this.convertUnitByType(
          this.averageWeightLengthVolumeType,
          this.currentProductGiveawayQuantityInStandardUnit,
          this.configuredUnit,
        );
        return Helpers.getRoundedValueOrZeroOrDash(
          giveawayConfiguredvalue,
          this.puHasRequiredFeature(this.puId, Tiles.productGiveaway.requiredFeature),
        );
      } else if (this.activeKpi === AVERAGE_WEIGHT_LENGTH_VOLUME) {
        let averagewvlConfiguredValue = this.convertUnitByType(
          this.averageWeightLengthVolumeType,
          this.averageWeightLengthVolumeValue,
          this.configuredUnit,
        );
        return Helpers.getRoundedValueOrZeroOrDash(
          averagewvlConfiguredValue,
          this.puHasRequiredFeature(this.puId, Tiles.productAverageGiveaway.requiredFeature),
        );
      } else {
        return Helpers.getRoundedPercentageOrDash(this.oeeValue, true);
      }
    },
    getKpiTarget() {
      // Target in PU card
      switch (this.activeKpi) {
        case OEE:
          return Helpers.getRoundedPercentageOrDash(this.oeeTarget);
        case AVAILABILITY:
          return Helpers.getRoundedPercentageOrDash(this.availabilityTarget);
        case PERFORMANCE: {
          return Helpers.getRoundedPercentageOrDash(
            this.performanceTarget,
            this.puHasRequiredFeature(this.puId, Tiles.performance.requiredFeature),
          );
        }
        case QUALITY: {
          // Reject module is necessary to show the Quality target
          return Helpers.getRoundedPercentageOrDash(
            this.qualityTarget,
            this.puHasRequiredFeature(this.puId, PackageFeatures.reject),
          );
        }
        case CURRENT_PRODUCT_QUANTITY:
          return Helpers.getRoundedValueOrDash(
            this.plannedQuantity,
            this.puHasRequiredFeature(this.puId, Tiles.currentProductQuantity.requiredFeature),
          );

        case RATE_PER_HOUR: {
          const target = Helpers.getRoundedValueOrDash(
            this.isActiveKpiConfigProductUnitCount ? this.ratePerHourTarget : this.wlvRatePerHourTarget,
            this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughput.requiredFeature),
            2,
          );
          return this.getConvertedValue(target);
        }
        case RATE_PER_MINUTE: {
          const target = Helpers.getRoundedValueOrDash(
            this.isActiveKpiConfigProductUnitCount ? this.ratePerMinuteTarget : this.wlvRatePerMinuteTarget,
            this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
            2,
          );
          return this.getConvertedValue(target);
        }
        case RATE_PER_SECOND: {
          const target = Helpers.getRoundedValueOrDash(
            this.isActiveKpiConfigProductUnitCount ? this.ratePerSecondTarget : this.wlvRatePerSecondTarget,
            this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerSecond.requiredFeature),
            2,
          );
          return this.getConvertedValue(target);
        }
        case SPEED5MINUTES_PER_HOUR: {
          const target = Helpers.getRoundedValueOrDash(
            this.isActiveKpiConfigProductUnitCount ? this.ratePerHourTarget : this.wlvRatePerHourTarget,
            this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5m.requiredFeature),
            2,
          );
          return this.getConvertedValue(target);
        }
        case SPEED5MINUTES_PER_MINUTE: {
          const target = Helpers.getRoundedValueOrDash(
            this.isActiveKpiConfigProductUnitCount ? this.ratePerMinuteTarget : this.wlvRatePerMinuteTarget,
            this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerMinute.requiredFeature),
            2,
          );
          return this.getConvertedValue(target);
        }
        case SPEED5MINUTES_PER_SECOND: {
          const target = Helpers.getRoundedValueOrDash(
            this.isActiveKpiConfigProductUnitCount ? this.ratePerSecondTarget : this.wlvRatePerSecondTarget,
            this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerSecond.requiredFeature),
            2,
          );
          return this.getConvertedValue(target);
        }
        case GIVEAWAY_PERCENTAGE: {
          return Helpers.getRoundedPercentageOrDash(
            this.giveawayPercentageTarget,
            this.puHasRequiredFeature(this.puId, Tiles.productGiveawayPercent.requiredFeature),
          );
        }
        case AVERAGE_WEIGHT_LENGTH_VOLUME: {
          let averagewvlConfiguredTarget = this.convertUnitByType(
            this.averageWeightLengthVolumeType,
            this.averageWeightLengthVolumeTarget,
            this.configuredUnit,
          );
          return Helpers.getRoundedValueOrDash(
            averagewvlConfiguredTarget,
            this.puHasRequiredFeature(this.puId, Tiles.productAverageGiveaway.requiredFeature),
          );
        }
        case OOE:
          return Helpers.getRoundedPercentageOrDash(this.ooeTarget, true);
        case COMPLETION_PERCENTAGE: {
          if (this.isLiveData) {
            if (this.completionNetQuantityValue && this.completionPlannedQuantityValue) {
              return Helpers.round(this.completionNetQuantityValue, 1) + "/" + Helpers.round(this.completionPlannedQuantityValue, 1);
            } else {
              return dash;
            }
          } else {
            return dash;
          }
        }
        default:
          return null;
      }
    },
    getKpiStatus() {
      // The circle in the PU card
      let kpiValue;
      let kpiTarget;
      if (this.activeKpi === OEE) {
        kpiValue = this.oeeValue;
        kpiTarget = this.oeeTarget;
      } else if (this.activeKpi === AVAILABILITY) {
        kpiValue = this.availabilityValue;
        kpiTarget = this.availabilityTarget;
      } else if (this.activeKpi === PERFORMANCE) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.performance.requiredFeature)) {
          return this.noMetricCssClass;
        }
        kpiValue = this.performanceValue;
        kpiTarget = this.performanceTarget;
      } else if (this.activeKpi === QUALITY) {
        // Reject module is necessary to show the Quality status color
        if (!this.puHasRequiredFeature(this.puId, PackageFeatures.reject)) {
          return this.noMetricCssClass;
        }
        kpiValue = this.qualityValue;
        kpiTarget = this.qualityTarget;
      } else if (this.activeKpi === ALL_PRODUCT_QUANTITY) {
        kpiValue = this.allProductQuantityValue;
      } else if (this.activeKpi === CURRENT_PRODUCT_QUANTITY) {
        if (
          !this.puHasRequiredFeature(this.puId, Tiles.currentProductQuantity.requiredFeature) ||
          this.currentProductQuantityValue === 0
        ) {
          return this.noMetricCssClass;
        }
        kpiValue = this.currentProductQuantityValue;
        kpiTarget = this.plannedQuantity;
      } else if (this.activeKpi === RATE_PER_HOUR) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughput.requiredFeature)) {
          return this.noMetricCssClass;
        }
        kpiValue = this.isActiveKpiConfigProductUnitCount ? this.ratePerHourValue : this.getConvertedValue(this.wlvRatePerHour);
        kpiTarget = this.isActiveKpiConfigProductUnitCount ? this.ratePerHourTarget : this.getConvertedValue(this.wlvRatePerHourTarget);
      } else if (this.activeKpi === RATE_PER_MINUTE) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerMinute.requiredFeature)) {
          return this.noMetricCssClass;
        }
        kpiValue = this.isActiveKpiConfigProductUnitCount ? this.ratePerMinuteValue : this.getConvertedValue(this.wlvRatePerMinute);
        kpiTarget = this.isActiveKpiConfigProductUnitCount ? this.ratePerMinuteTarget : this.getConvertedValue(this.wlvRatePerMinuteTarget);
      } else if (this.activeKpi === RATE_PER_SECOND) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductThroughputPerSecond.requiredFeature)) {
          return this.noMetricCssClass;
        }
        kpiValue = this.isActiveKpiConfigProductUnitCount ? this.ratePerSecondValue : this.getConvertedValue(this.wlvRatePerSecond);
        kpiTarget = this.isActiveKpiConfigProductUnitCount ? this.ratePerSecondTarget : this.getConvertedValue(this.wlvRatePerSecondTarget);
      } else if (this.activeKpi === SPEED5MINUTES_PER_HOUR) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5m.requiredFeature)) {
          return this.noMetricCssClass;
        }
        if (this.speedFiveMinuteValuePerMinute === null || this.speedFiveMinuteValuePerMinute === 0) {
          return this.noMetricCssClass;
        } else {
          kpiValue = this.speedFiveMinuteValue;
          kpiTarget = this.ratePerHourTarget;
        }
      } else if (this.activeKpi === SPEED5MINUTES_PER_MINUTE) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerMinute.requiredFeature)) {
          return this.noMetricCssClass;
        }
        if (this.speedFiveMinuteValuePerMinute == null || this.speedFiveMinuteValuePerMinute === 0) {
          return this.noMetricCssClass;
        } else {
          kpiValue = this.speedFiveMinuteValuePerMinute;
          kpiTarget = this.ratePerMinuteTarget;
        }
      } else if (this.activeKpi === SPEED5MINUTES_PER_SECOND) {
        if (!this.puHasRequiredFeature(this.puId, Tiles.currentProductSpeed5mPerSecond.requiredFeature)) {
          return this.noMetricCssClass;
        }
        if (this.speedFiveMinuteValuePerSecond == null || this.speedFiveMinuteValuePerSecond === 0) {
          return this.noMetricCssClass;
        } else {
          kpiValue = this.speedFiveMinuteValuePerSecond;
          kpiTarget = this.ratePerSecondTarget;
        }
      } else if (this.activeKpi === OOE) {
        kpiValue = this.ooeValue;
        kpiTarget = this.ooeTarget;
      } else if (this.activeKpi === UPTIME) {
        kpiValue = this.uptimeValue;
      } else if (this.activeKpi === PLANNED_UNPLANNED_DOWNTIME) {
        kpiValue = [this.unplannedDowntimeValue, this.plannedDowntimeValue];
      } else if (this.activeKpi === NET_REJECT_QUANTITY) {
        kpiValue = [this.netQuantityValue, this.rejectQuantityValue];
      } else if (this.activeKpi === TIME_TO_COMPLETION) {
        kpiValue = this.timeToCompletionValue;
      } else if (this.activeKpi === COMPLETION_PERCENTAGE) {
        kpiValue = this.completionPercentageValue;
      } else if (this.activeKpi === TOTAL_WEIGHT) {
        kpiValue = this.totalWeightValue;
      } else if (this.activeKpi === TOTAL_VOLUME) {
        kpiValue = this.totalVolumeValue;
      } else if (this.activeKpi === TOTAL_LENGTH) {
        kpiValue = this.totalLengthValue;
      } else if (this.activeKpi === GIVEAWAY_PERCENTAGE) {
        if (this.giveawayPercentageTarget == null || this.giveawayPercentageTarget === 0) {
          return this.noMetricCssClass;
        } else {
          kpiValue = this.giveawayPercentageValue;
          kpiTarget = this.giveawayPercentageTarget;
        }
      } else if (this.activeKpi === GIVEAWAY) {
        kpiValue = this.currentProductGiveawayQuantityInStandardUnit;
      } else if (this.activeKpi === AVERAGE_WEIGHT_LENGTH_VOLUME) {
        return this.noMetricCssClass; // no status in dashboard right now, so no status in overview for conformity purpose.
      } else {
        // Previously, there was a bottom label in the tile. Now, without it, it's difficult to understand the data versus the incoherent KPI active choice.
        // So, we reset to OEE.
        this.setActiveKpi({ activeKpi: "oee" });
        kpiValue = this.oeeValue;
        kpiTarget = this.oeeTarget;
      }

      if (kpiValue == null || kpiTarget == null) return this.noMetricCssClass;
      let percentageValue = 100.0 * ((kpiValue - kpiTarget) / kpiTarget);
      switch (this.activeKpi) {
        case OEE:
        case AVAILABILITY:
        case PERFORMANCE:
        case OOE:
          return this.getStatus(kpiValue - kpiTarget);
        case GIVEAWAY_PERCENTAGE:
          return this.getStatus(Math.abs(kpiValue) - kpiTarget, 0, -1, true);
        default: {
          if (kpiTarget != null && kpiTarget >= 0) {
            return this.getStatus(percentageValue);
          } else {
            return this.noMetricCssClass;
          }
        }
      }
    },
    getKpiName() {
      if (this.activeKpi === PLANNED_UNPLANNED_DOWNTIME) {
        return [this.$t("overview.details.downtimes.unplanned"), this.$t("overview.details.downtimes.planned")];
      } else if (this.activeKpi === NET_REJECT_QUANTITY) {
        if (!this.preferences.overview.product_unit) {
          return [
            this.$t("overview.metrics.netQuantity_unit", { unit: this.convertedUnitName }),
            this.$t("overview.metrics.rejectQuantity"),
          ];
        } else {
          return [
            this.$t("overview.metrics.netQuantity_unit", { unit: this.preferences.overview.product_unit }),
            this.$t("overview.metrics.rejectQuantity"),
          ];
        }
      } else return "";
    },
    bottomLabel() {
      switch (this.activeKpi) {
        case ALL_PRODUCT_QUANTITY:
          return this.convertedUnitName;
        case CURRENT_PRODUCT_QUANTITY:
          return this.convertedUnitName;
        case RATE_PER_HOUR:
          return this.getThroughputSpeedBottomLabel(this.$t("common.perHour"));
        case RATE_PER_MINUTE:
          return this.getThroughputSpeedBottomLabel(this.$t("common.perMinute"));
        case SPEED5MINUTES_PER_HOUR:
          return this.getThroughputSpeedBottomLabel(this.$t("common.perHour"));
        case SPEED5MINUTES_PER_MINUTE:
          return this.getThroughputSpeedBottomLabel(this.$t("common.perMinute"));
        case TOTAL_WEIGHT:
          if (this.isTotalWeightPreferences) {
            const targetUnit = this.preferences.overview.product_unit;
            return targetUnit;
          }
          return this.totalWeightStandardUnit ? this.totalWeightStandardUnit : dash;
        case TOTAL_VOLUME:
          if (this.isTotalVolumePreferences) {
            const targetUnit = this.preferences.overview.product_unit;
            return targetUnit;
          }
          return this.totalVolumeStandardUnit ? this.totalVolumeStandardUnit : dash;
        case TOTAL_LENGTH:
          if (this.isTotalLengthPreferences) {
            const targetUnit = this.preferences.overview.product_unit;
            return targetUnit;
          }
          return this.totalLengthStandardUnit ? this.totalLengthStandardUnit : dash;
        case AVERAGE_WEIGHT_LENGTH_VOLUME:
        case GIVEAWAY: {
          if (this.puHasRequiredFeature(this.puId, Tiles.productGiveawayPercent.requiredFeature)) {
            return getUnitLabel(this.configuredUnit) || getUnitLabel(this.standardUnit);
          } else {
            return "";
          }
        }
        default:
          return "";
      }
    },
    convertedUnitName() {
      let convertedUnitName = this.activeFactoryProductionUnits?.find((pu) => pu.id === this.puId)?.convertedUnitName;
      let unit = ProductionUnitService.getUnitName(convertedUnitName);
      return unit === "unit" || !unit ? this.$t("common.units.unit") : unit;
    },
    buttonHoverTitle() {
      return this.$t("overview.productionUnit.buttonHoverTitle");
    },
    plannedQuantity() {
      let pu = this.activeProductionUnitsCoverage.find((pu) => pu.production_unit_id === this.puId);

      if (!pu || !pu.production_runs_coverage || pu.production_runs_coverage.length === 0) {
        return null;
      }

      let latestStartDateIndex = 0;
      let latestStartDate = new Date(pu.production_runs_coverage[0].start_date);
      pu.production_runs_coverage.forEach((coverage, index) => {
        const startDate = new Date(coverage.start_date);
        if (startDate > latestStartDate) {
          latestStartDate = startDate;
          latestStartDateIndex = index;
        }
      });
      const plannedQuantity = pu.production_runs_coverage[latestStartDateIndex].planned_quantity;
      return plannedQuantity;
    },
    isOverviewPreferencesKpiSelected() {
      return this.preferences?.overview?.selected_kpi && this.preferences?.overview?.product_unit;
    },
    isTotalWeightPreferences() {
      return this.isOverviewPreferencesKpiSelected && this.preferences?.overview?.selected_kpi === "total_weight"
    },
    isTotalVolumePreferences() {
      return this.isOverviewPreferencesKpiSelected && this.preferences?.overview?.selected_kpi === "total_volume"
    },
    isTotalLengthPreferences() {
      return this.isOverviewPreferencesKpiSelected && this.preferences?.overview?.selected_kpi === "total_length"
    },
    successCssClass() {
      return Accessibility.addPalette("good");
    },
    warningCssClass() {
      return Accessibility.addPalette("average");
    },
    errorCssClass() {
      return Accessibility.addPalette("bad");
    },
    noMetricCssClass() {
      return Accessibility.addPalette("no-metric");
    },
  },
  watch: {
    isDisconnected: {
      immediate: true,
      handler() {
        if (this.isDisconnected) {
          this.startDisconnectedInterval();
        } else {
          clearInterval(this.disconnectedInterval);
        }
      },
    },
  },
  methods: {
    ...mapActions("dashboard", ["setActiveProductionUnitId", "setActiveTabIndex"]),
    ...mapActions("overview", ["setActiveKpi"]),
    getCardCssClass(puStatus) {
      if (this.isDisconnected) {
        return this.getDisconnectedCssClass();
      } else {
        return Accessibility.addPalette(puStatus);
      }
    },
    getDisconnectedCssClass() {
      return Accessibility.addPalette("disconnected");
    },
    getConvertedValue(value) {
      const unit = this.activeKpiConfig?.product_unit;
      if (!unit) return value;
      const unitType = getUnitType(unit);
      switch (unitType) {
        case "product_unit": {
          return value;
        }
        case "weight_unit": {
          // value was asked to be shown in weight but the current wlv value is not in weight
          if (this.wlvRateUnit !== "kg") return null;
          return convertKilogramTo(value, unit);
        }
        case "length_unit": {
          // value was asked to be shown in weight but the current wlv value is not in weight
          if (this.wlvRateUnit !== "m") return null;
          return convertMeterTo(value, unit);
        }
        case "volume_unit": {
          // value was asked to be shown in weight but the current wlv value is not in weight
          if (this.wlvRateUnit !== "l") return null;
          return convertLiterTo(value, unit);
        }
      }
    },
    goToDashboard() {
      if (this.isDisconnected) {
        this.openDialog();
      } else {
        // Set every fields necessary to load the dashboard for the RIGHT production unit!
        this.setActiveProductionUnitId(this.puId);

        this.$router.push(RouteService.toDashboard(this.puId, this.getDashboardTab()));
      }
    },
    getDashboardTab() {
      return this.preferences.dashboards.production_units.find(pu => pu.id === this.puId)?.tab ?? "timeline";
    },
    startDisconnectedInterval() {
      this.setDisconnectedDuration();
      this.disconnectedInterval = setInterval(() => this.setDisconnectedDuration(), 1000);
    },
    setDisconnectedDuration() {
      const milliseconds = Date.now() - this.isDisconnectedSince;
      this.disconnectedDuration = Duration.fromMillis(milliseconds).toFormat("hh:mm:ss");
      this.disconnectedStartDate = TimeUtils.fromEpochMillis(
        this.isDisconnectedSince,
        this.activeFactory.timezone,
        DATE_TIME_FORMAT_NO_TIMEZONE,
      );
    },
    openDialog() {
      this.dialogState = true;
    },
    closeDialog() {
      this.dialogState = false;
    },
    toggleDetails(index) {
      this.$emit("toggleDetails", { production_unit_id: this.puId, index: index });
    },
    getStatus(valueInPercentage, goodThreshold = 0, averageThreshold = -5, invertSign = false) {
      if (invertSign) {
        valueInPercentage = -valueInPercentage;
      }
      let status;
      if (valueInPercentage >= goodThreshold) {
        status = this.successCssClass;
      } else if (valueInPercentage >= averageThreshold) {
        status = this.warningCssClass;
      } else {
        status = this.errorCssClass;
      }
      return status;
    },
    convertUnitByType(type, value, targetUnit) {
      switch (type) {
        case "weight":
          return convertKilogramTo(value, targetUnit);
        case "length":
          return convertMeterTo(value, targetUnit);
        case "volume":
          return convertLiterTo(value, targetUnit);
        default:
          return null;
      }
    },
    getThroughputSpeedBottomLabel(timeUnitLabel) {
      // converted units is selected
      if (!this.activeKpiConfig.product_unit) {
        return this.convertedUnitName + timeUnitLabel;
      } else {
        return getUnitName(this.activeKpiConfig.product_unit).name + timeUnitLabel;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.disconnectedInterval);
  },
};
</script>

<style lang="scss" scoped>
// local var
$tilesBorderColor: transparent;
$tilesBorderValue: 0;
$tilesBorderWidth: #{$tilesBorderValue}px;
$doubleTilesBordersWidth: #{(2 * $tilesBorderValue)}px;

/**
  * PRODUCT UNITS CARDS
  * These cards are styles as Dashboard Tiles
  * with the exception of the thicker top-border
  * size defined by `.pu-card:after` border-width
  * and border-color by the by PU states (below).
  */
.pu-card {
  &.v-card {
    position: relative;
    margin-top: var(--border-radius-lg);
    padding: var(--box-padding-dashboard);
    border: $tilesBorderWidth solid $tilesBorderColor;
    border-top: none; // top-border = .pu-card:after
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg) !important;
    background-color: var(--color-base-background) !important;
    cursor: pointer;
    font-size: 1vh;

    &.v-card {
      box-shadow: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(var(--border-radius-lg) * -1); // turn it in negative value
      left: -($tilesBorderWidth);
      width: calc(100% + #{$doubleTilesBordersWidth});
      height: var(--border-radius-lg);
      border: $tilesBorderWidth solid transparent;
      border-bottom-width: 0;
      border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    }

    .card-body {
      padding-bottom: 4px;

      &:not(.is-presenter) {
        padding-bottom: calc(var(--box-padding-dashboard) + 30px); // match v-btn height
      }

      &.is-fullscreen {
        height: calc(50vh - (200px + var(--border-radius-lg)));
      }
    }
    .card-footer {
      position: absolute;
      bottom: 0;
      width: calc(100% - var(--box-padding-dashboard) - var(--box-padding-dashboard));
      padding-bottom: var(--box-padding-dashboard);

      .btn-pu-details-control {
        .v-icon {
          transform: rotate(0deg);
        }
      }
    }

    /**
   * PU states
   */
    // --------------------------------------------------------------------------------------------------
    // Up
    // --------------------------------------------------------------------------------------------------
    &.up:after {
      background-color: var(--color-uptime);
    }
    &.up-accessible:after {
      background-color: var(--color-uptimeAccessible);
    }
    // --------------------------------------------------------------------------------------------------
    // Unjustified
    // --------------------------------------------------------------------------------------------------
    &.down_unjustified:after {
      background-color: var(--color-error);
    }
    &.down_unjustified-accessible:after {
      background-color: var(--color-errorAccessible);
    }
    // --------------------------------------------------------------------------------------------------
    // Unplanned
    // --------------------------------------------------------------------------------------------------
    &.down_unplanned:after {
      background-color: var(--color-justifiedDowntime);
    }
    &.down_unplanned-accessible:after {
      background-color: var(--color-justifiedDowntimeAccessible);
    }
    // --------------------------------------------------------------------------------------------------
    // Planned
    // --------------------------------------------------------------------------------------------------
    &.down_planned:after {
      background-color: var(--color-plannedDowntime);
    }
    &.down_planned-accessible:after {
      background-color: var(--color-plannedDowntimeAccessible);
    }
    // --------------------------------------------------------------------------------------------------
    // Out of production
    // --------------------------------------------------------------------------------------------------
    &.out_of_production:after {
      background-color: var(--color-outOfProduction);
      border-color: var(--color-border-theme);
    }
    &.out_of_production-accessible:after {
      background-color: var(--color-outOfProductionAccessible);
      border-color: var(--color-border-theme);
    }
    // --------------------------------------------------------------------------------------------------
    // Unknown
    // --------------------------------------------------------------------------------------------------
    &.unknown:after {
      background-color: var(--color-unknown);
      border-color: var(--color-border-theme);
    }
    &.unknown-accessible:after {
      background-color: var(--color-unknownAccessible);
      border-color: var(--color-border-theme);
    }
    // --------------------------------------------------------------------------------------------------
    // Carbon-frozen. It means the data is from the past and won't change, even if you keep refreshing
    // --------------------------------------------------------------------------------------------------
    &.carbon-frozen:after {
      // carbon frozen ? Yes, like Han Solo in The Empire Strikes Back :D
      background-color: inherit;
      border-color: var(--color-border-theme);
    }

    // .pu-card.details-active
    &.details-active {
      background-color: var(--color-element-layer2) !important;
      box-shadow: var(--box-shadow-high-elevation);

      .card-footer {
        .btn-pu-details-control {
          background-color: var(--color-control-subtle-theme);

          .v-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
    // --------------------------------------------------------------------------------------------------
    // Disconnection PU
    // --------------------------------------------------------------------------------------------------
    &.disconnected {
      background-color: var(--color-disconnected) !important;

      &:after {
        border-bottom: 0;
        background-color: var(--color-disconnected);
      }

      .wx-typo-h1 {
        display: inline;
        white-space: normal;
      }

      // Based on Martine comments it should not be visible
      .btn-pu-details-control {
        display: none;
      }

      // Light mode does NOT get text-shadow
      @at-root {
        .theme--dark {
          .wx-typo-h2 {
            text-shadow: var(--text-shadow-small-level);
          }
          .pu-subheader {
            text-shadow: var(--text-shadow-smallest-level);
          }
          .wx-typo-h1 {
            text-shadow: var(--text-shadow-high-level);
          }
        }
      }
    }
    &.disconnected-accessible {
      background-color: var(--color-disconnectedAccessible) !important;

      &:after {
        border-bottom: 0;
        background-color: var(--color-disconnectedAccessible);
      }

      .wx-typo-h1 {
        display: inline;
        white-space: normal;
      }

      // Based on Martine comments it should not be visible
      .btn-pu-details-control {
        display: none;
      }

      // Light mode does NOT get text-shadow
      @at-root {
        .theme--dark {
          .wx-typo-h2 {
            text-shadow: var(--text-shadow-small-level);
          }
          .pu-subheader {
            text-shadow: var(--text-shadow-smallest-level);
          }
          .wx-typo-h1 {
            text-shadow: var(--text-shadow-high-level);
          }
        }
      }
    }
  }
}

// This weird CSS is to get "text-overflow: ellipsis" to work with a fluid width
.ellipsis {
  position: relative;

  &:before {
    content: "&nbsp;";
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.kpi-circle {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 10px solid transparent;

  &.is-fullscreen {
    font-size: 3vh;
  }

  &:not(.is-fullscreen) {
    font-size: 2vh;
  }

  &:before {
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 50%;
  }

  @media ($wx-sm-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 120px;
      height: 120px;
      border: 12px solid transparent;
    }

    &:before {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
    }
  }

  @media ($wx-md-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 140px;
      height: 140px;
      border: 14px solid transparent;
    }

    &:before {
      width: calc(100% - 14px);
      height: calc(100% - 14px);
    }
  }

  @media ($wx-lg-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 160px;
      height: 160px;
    }

    &:before {
      width: calc(100% - 16px);
      height: calc(100% - 16px);
    }
  }

  @media ($wx-xl-min) {
    &.is-fullscreen {
      width: calc(50vh - 350px);
      height: calc(50vh - 350px);
    }
    &:not(.is-fullscreen) {
      width: 180px;
      height: 180px;
    }

    &:before {
      width: calc(100% - 25px);
      height: calc(100% - 25px);
    }
  }

  // --------------------------------------------------------------------------------------------------
  // Above the target
  // --------------------------------------------------------------------------------------------------
  &.good {
    border-color: var(--color-success);
    &:before {
      background: var(--color-success-alpha);
    }
  }
  &.good-accessible {
    border-color: var(--color-successAccessible);
    &:before {
      background: var(--color-successAccessible-alpha);
    }
  }

  // --------------------------------------------------------------------------------------------------
  // Under the target, but close
  // --------------------------------------------------------------------------------------------------
  &.average {
    border-color: var(--color-warning);
    &:before {
      background: var(--color-warning-alpha);
    }
  }
  &.average-accessible {
    border-color: var(--color-warningAccessible);
    &:before {
      background: var(--color-warningAccessible-alpha);
    }
  }

  // --------------------------------------------------------------------------------------------------
  // Under the target
  // --------------------------------------------------------------------------------------------------
  &.bad {
    border-color: var(--color-error);
    &:before {
      background: var(--color-error-alpha);
    }
  }
  &.bad-accessible {
    border-color: var(--color-errorAccessible);
    &:before {
      background: var(--color-errorAccessible-alpha);
    }
  }

  // --------------------------------------------------------------------------------------------------
  // No metric
  // --------------------------------------------------------------------------------------------------
  &.no-metric {
    border-color: var(--color-neutral);
    &:before {
      background: var(--color-neutral-alpha);
    }
  }
  &.no-metric-accessible {
    border-color: var(--color-neutralAccessible);
    &:before {
      background: var(--color-neutralAccessible-alpha);
    }
  }
}

.kpi-value,
.kpi-label {
  position: relative;
  z-index: 1;
}

.kpi-name {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.invisible-chip {
  visibility: hidden;
}
</style>
