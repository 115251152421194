<template>
  <aside class="pu-details">
    <h3 class="title">{{ name }}</h3>

    <v-btn icon large @click="$emit('closeDetails')" :aria-label="$t('common.close')" class="close-btn">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <template v-if="isXs">
      <v-tabs v-model="activeTab" grow slider-size="5" class="mb-4">
        <v-tab>{{ $t("overview.details.kpis.title") }}</v-tab>
        <v-tab>{{ $t("overview.details.downtimes.title") }}</v-tab>
        <v-tab>{{ $t("overview.details.time.tabTitle") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <p-u-details-kpis />
        </v-tab-item>
        <v-tab-item>
          <p-u-details-downtimes />
        </v-tab-item>
        <v-tab-item>
          <p-u-details-time />
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-else-if="isSm">
      <p-u-details-kpis />
      <p-u-details-time />
      <p-u-details-downtimes />
    </template>

    <template v-else>
      <p-u-details-kpis />
      <p-u-details-downtimes />
      <p-u-details-time />
    </template>
  </aside>
</template>

<script>
import PUDetailsKpis from "@/components/overview/PUDetailsKpis";
import PUDetailsDowntimes from "@/components/overview/PUDetailsDowntimes";
import PUDetailsTime from "@/components/overview/PUDetailsTime";
import { mapActions } from "vuex";

export default {
  name: "PUDetails",
  components: {
    PUDetailsKpis,
    PUDetailsDowntimes,
    PUDetailsTime,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    productionUnitId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    isSm() {
      return this.$vuetify.breakpoint.sm;
    },
  },
  methods: {
    ...mapActions("overview", ["fetchProductionUnitOverview"]),
  },
  mounted() {
    this.fetchProductionUnitOverview(this.productionUnitId);
  },
};
</script>

<style lang="scss" scoped>
.pu-details {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  grid-column: 1 / -1;
  padding: var(--box-padding-dashboard);
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-lg);
  background-color: var(--color-element-layer2);
  box-shadow: var(--box-shadow-high-elevation);

  @media ($wx-sm-min) {
    border: none;
    background-color: var(--color-base-layer1);
    box-shadow: none;
  }
}

.title {
  width: 100%;
  padding-right: 40px;
  margin-bottom: var(--box-padding-dashboard);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.v-tab {
  min-width: 80px;
  padding: 0 12px;
}
.v-tabs-items {
  width: 100%;
}

::v-deep .wx-tile-panel {
  background-color: var(--color-element-layer2);

  @media ($wx-sm-min) {
    &.pu-details-kpis,
    &.pu-details-timeline {
      width: calc((100% - var(--box-padding-dashboard)) / 2);
    }

    &.pu-details-kpis {
      margin-right: var(--box-padding-dashboard);
    }

    &.pu-details-downtimes {
      width: 100%;
      margin-top: var(--box-padding-dashboard);
    }
  }

  @media ($wx-md-min) {
    &.pu-details-kpis,
    &.pu-details-downtimes,
    &.pu-details-timeline {
      width: calc((100% - var(--box-padding-dashboard) * 2) / 3);
    }

    &.pu-details-kpis,
    &.pu-details-downtimes {
      margin-right: var(--box-padding-dashboard);
    }

    &.pu-details-downtimes {
      margin-top: 0;
    }
  }
}
</style>
