<template>
  <DoughnutChartGenerator
    v-if="chartData"
    :chart-options="getChartOptions()"
    :chart-data="chartData"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Doughnut as DoughnutChartGenerator } from "vue-chartjs/legacy";
import annotationPlugin from "chartjs-plugin-annotation";
import { Chart, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

Chart.register(Title, Tooltip, Legend, ArcElement, CategoryScale, annotationPlugin);

import "chartjs-adapter-luxon";
export default {
  name: "WxChartDoughnutGraph",
  components: {
    DoughnutChartGenerator,
  },
  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [
        {
          id: "transparant_background_plugin",
          beforeDraw: (chart) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = "transparent";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          },
        },
      ],
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getChartOptions() {
      return { ...this.chartOptions };
    },
  },
};
</script>
<style lang="scss" scoped></style>
