<template>
  <fragment>
    <div class="overviewTitle">
      <div v-if="!isFullScreen" class="PU-list" :class="[clickedChildState ? 'child-clicked' : false]">
        <template v-for="(pu, index) in productionUnitsStateKpisAndAlert">
          <p-u-item
            :key="'overviewGridPu-' + index"
            :pu-id="pu.puId"
            :pu-name="pu.puName"
            :pu-status="pu.puStatus"
            :product-sku="pu.productSku"
            :product-name="pu.productName"
            :kpi-selected="pu.kpiSelected"
            :oee-value="pu.oeeValue"
            :oee-target="pu.oeeTarget"
            :ooe-value="pu.ooeValue"
            :ooe-target="pu.ooeTarget"
            :availability-value="pu.availabilityValue"
            :availability-target="pu.availabilityTarget"
            :performance-value="pu.performanceValue"
            :performance-target="pu.performanceTarget"
            :quality-value="pu.qualityValue"
            :quality-target="pu.qualityTarget"
            :all-product-quantity-value="pu.allProductQuantityValue"
            :current-product-quantity-value="pu.currentProductQuantityValue"
            :rate-per-hour-value="pu.ratePerHourValue"
            :rate-per-hour-target="pu.ratePerHourTargetValue"
            :wlv-rate-per-hour="pu.wlvRatePerHourValue"
            :wlv-rate-per-hour-target="pu.wlvRatePerHourTargetValue"
            :wlv-rate-unit="pu.wlvRateUnit"
            :rate-per-minute-value="pu.ratePerMinuteValue"
            :rate-per-minute-target="pu.ratePerMinuteTargetValue"
            :rate-per-second-value="pu.ratePerSecondValue"
            :rate-per-second-target="pu.ratePerSecondTargetValue"
            :wlv-rate-per-minute="pu.wlvRatePerMinuteValue"
            :wlv-rate-per-minute-target="pu.wlvRatePerMinuteTargetValue"
            :wlv-rate-per-second="pu.wlvRatePerSecondValue"
            :wlv-rate-per-second-target="pu.wlvRatePerSecondTargetValue"
            :speed-five-minute-value="pu.speedFiveMinuteValue"
            :speed-five-minute-value-per-minute="pu.speedFiveMinuteValuePerMinute"
            :speed-five-minute-value-per-second="pu.speedFiveMinuteValuePerSecond"
            :uptime-value="pu.uptimeValue"
            :planned-downtime-value="pu.plannedDowntimeValue"
            :unplanned-downtime-value="pu.unplannedDowntimeValue"
            :net-quantity-value="pu.netQuantityValue"
            :reject-quantity-value="pu.rejectQuantityValue"
            :total-weight-value="pu.totalWeightValue"
            :total-weight-standard-unit="pu.totalWeightStandardUnit"
            :total-rejected-weight-value="pu.rejectedWeightValue"
            :total-volume-value="pu.totalVolumeValue"
            :total-volume-standard-unit="pu.totalVolumeStandardUnit"
            :total-rejected-volume-value="pu.rejectedVolumeValue"
            :total-length-value="pu.totalLengthValue"
            :total-length-standard-unit="pu.totalLengthStandardUnit"
            :total-rejected-length-value="pu.rejectedLengthValue"
            :giveaway-percentage-value="pu.giveawayPercentageValue"
            :giveaway-percentage-target="pu.giveawayPercentageTarget"
            :current-product-giveaway-quantity-in-standard-unit="pu.currentProductGiveawayQuantityInStandardUnit"
            :average-weight-length-volume-value="pu.averageWeightLengthVolumeValue"
            :average-weight-length-volume-target="pu.averageWeightLengthVolumeTarget"
            :average-weight-length-volume-type="pu.averageWeightLengthVolumeType"
            :configured-unit="pu.configuredUnit"
            :standard-unit="pu.standardUnit"
            :is-disconnected="pu.isDisconnected || false"
            :is-disconnected-since="pu.isDisconnectedSince"
            :time-to-completion-value="pu.timeToCompletionValue"
            :completion-percentage-value="pu.completionPercentageValue"
            :completion-net-quantity-value="pu.completionNetQuantityValue"
            :completion-planned-quantity-value="pu.completionPlannedQuantityValue"
            :index="index"
            :details-active="index === detailsIndex"
            :is-full-screen="isFullScreen"
            @toggleDetails="toggleDetailsByIndex"
            @closeDetails="closeDetails"
          />

          <p-u-details
            v-if="index === detailsIndex"
            :key="'overviewPuDetails-' + index"
            :name="pu.puName"
            :production-unit-id="pu.puId"
            @closeDetails="closeDetails"
          />
        </template>
      </div>
      <v-carousel
        v-else
        v-model="carouselIndex"
        continuous
        cycle
        interval="15000"
        hide-delimiter-background
        :show-arrows="false"
        height="calc(100vh - 200px)"
      >
        <v-carousel-item
          v-for="(window, index) in carouselProductionUnitsStateKpisAndAlert"
          :key="`carousel-item-${index}`"
        >
          <v-row style="height: 100%">
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="4"
              lg="3"
              v-for="(pu, index) in window"
              :key="'overviewGridPu-' + index"
            >
              <p-u-item
                :pu-id="pu.puId"
                :pu-name="pu.puName"
                :pu-status="pu.puStatus"
                :product-sku="pu.productSku"
                :product-name="pu.productName"
                :oee-value="pu.oeeValue"
                :oee-target="pu.oeeTarget"
                :ooe-value="pu.ooeValue"
                :availability-value="pu.availabilityValue"
                :availability-target="pu.availabilityTarget"
                :performance-value="pu.performanceValue"
                :performance-target="pu.performanceTarget"
                :quality-value="pu.qualityValue"
                :quality-target="pu.qualityTarget"
                :all-product-quantity-value="pu.allProductQuantityValue"
                :current-product-quantity-value="pu.currentProductQuantityValue"
                :rate-per-hour-value="pu.ratePerHourValue"
                :rate-per-hour-target="pu.ratePerHourTargetValue"
                :wlv-rate-per-hour="pu.wlvRatePerHourValue"
                :rate-per-minute-value="pu.ratePerMinuteValue"
                :rate-per-minute-target="pu.ratePerMinuteTargetValue"
                :wlv-rate-per-minute="pu.wlvRatePerMinuteValue"
                :speed-five-minute-value="pu.speedFiveMinuteValue"
                :speed-five-minute-value-per-minute="pu.speedFiveMinuteValuePerMinute"
                :current-production-run-quantity-value="pu.currentProductionRunQuantityValue"
                :uptime-value="pu.uptimeValue"
                :planned-downtime-value="pu.plannedDowntimeValue"
                :unplanned-downtime-value="pu.unplannedDowntimeValue"
                :net-quantity-value="pu.netQuantityValue"
                :reject-quantity-value="pu.rejectQuantityValue"
                :total-weight-value="pu.totalWeightValue"
                :total-weight-standard-unit="pu.totalWeightStandardUnit"
                :total-volume-value="pu.totalVolumeValue"
                :total-volume-standard-unit="pu.totalVolumeStandardUnit"
                :total-length-value="pu.totalLengthValue"
                :total-length-standard-unit="pu.totalLengthStandardUnit"
                :giveaway-percentage-value="pu.giveawayPercentageValue"
                :giveaway-value="pu.giveawayValue"
                :average-weight-length-volume-value="pu.averageWeightLengthVolumeValue"
                :average-weight-length-volume-standard-unit="pu.averageWeightLengthVolumeUnit"
                :is-disconnected="pu.isDisconnected || false"
                :is-disconnected-since="pu.isDisconnectedSince"
                :time-to-completion-value="pu.timeToCompletionValue"
                :completion-percentage-value="pu.completionPercentageValue"
                :completion-net-quantity-value="pu.completionNetQuantityValue"
                :completion-planned-quantity-value="pu.completionPlannedQuantityValue"
                :index="index"
                :is-full-screen="isFullScreen"
                @toggleDetails="toggleDetailsByIndex"
                @closeDetails="closeDetails"
              />
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>
  </fragment>
</template>

<script>
import PUItem from "@/components/overview/PUItem";
import PUDetails from "@/components/overview/PUDetails";
import { mapActions, mapGetters } from "vuex";
import { getUnitType } from "@/components/user/UserPreferencesService";
import { convertKilogramTo, convertLiterTo, convertMeterTo } from "@/UnitUtils";

export default {
  name: "PUList",
  components: { PUItem, PUDetails },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailsIndex: -1,
      clickedChildState: false,
      carouselIndex: 0,
    };
  },
  computed: {
    ...mapGetters("overview", [
      "productionUnitsStateAndKpis",
      "productionUnitsSpeed",
      "activeProductionUnitIdForDetails",
      "productionCompletionByPu",
      "activeKpiConfig",
    ]),
    ...mapGetters("navigation", ["activeFactory", "activeFactoryDataSourceAlerts"]),
    maxWindowSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 2;
        case "sm":
          return 4;
        case "md":
          return 6;
        default:
          return 8;
      }
    },
    carouselProductionUnitsStateKpisAndAlert() {
      if (!this.isFullScreen) return [];
      const items = this.productionUnitsStateKpisAndAlert;
      const maxWindowSize = this.maxWindowSize;

      let windows = [];
      let currentWindow = [];

      for (let i = 0; i < items.length; i++) {
        if (currentWindow.length >= maxWindowSize) {
          windows.push(currentWindow);
          currentWindow = [];
        }
        currentWindow.push(items[i]);
      }

      if (currentWindow.length > 0) {
        windows.push(currentWindow);
      }
      return windows;
    },
    productionUnitsStateKpisAndAlert() {
      if (this.activeFactory) {
        let alertsByPU = new Map();
        this.activeFactoryDataSourceAlerts
          .filter((a) => a.factory_id === this.activeFactory.id)
          .forEach((a) => {
            a.production_unit_ids.forEach((puId) => {
              let puAlerts = alertsByPU.get(puId);
              if (puAlerts) {
                puAlerts.push(a);
              } else {
                puAlerts = [];
                puAlerts.push(a);
                alertsByPU.set(puId, puAlerts);
              }
            });
          });

        this.productionUnitsStateAndKpis.forEach((puStateKpis) => {
          // Alert
          puStateKpis.isDisconnected = false;
          puStateKpis.isDisconnectedSince = null;
          const alerts = alertsByPU.get(puStateKpis.puId);
          if (alerts && alerts.length > 0) {
            const inactiveSinceValues = alerts.map((a) => a.inactive_since);
            puStateKpis.isDisconnected = true;
            puStateKpis.isDisconnectedSince = Math.min(...inactiveSinceValues);
          }

          // Speed
          puStateKpis.speedFiveMinuteValue = null;
          puStateKpis.speedFiveMinuteValuePerMinute = null;
          puStateKpis.speedFiveMinuteValuePerSecond = null;
          const speedForPU = this.productionUnitsSpeed.find(
            (speedForPU) => speedForPU.production_unit_id === puStateKpis.puId,
          );
          if (speedForPU) {
            const speedInUnitsPerHour = this.getSpeed5MinuteForPu(speedForPU);
            puStateKpis.speedFiveMinuteValue = speedInUnitsPerHour;
            puStateKpis.speedFiveMinuteValuePerMinute = speedInUnitsPerHour ? speedInUnitsPerHour / 60.0 : null;
            puStateKpis.speedFiveMinuteValuePerSecond = speedInUnitsPerHour ? speedInUnitsPerHour / 3600.0 : null;
          }

          // Completion
          const completion = this.productionCompletionByPu[puStateKpis.puId];
          if (completion) {
            puStateKpis.completionPercentageValue = completion.completionPercentageValue;
            puStateKpis.completionNetQuantityValue = completion.completionNetQuantityValue;
            puStateKpis.completionPlannedQuantityValue = completion.completionPlannedQuantityValue;
            puStateKpis.timeToCompletionValue = completion.timeToCompletionValue;
          }
        });
      }
      return this.productionUnitsStateAndKpis;
    },
  },
  methods: {
    ...mapActions("overview", ["clearProductionUnitIdForDetails", "setActiveProductionUnitIdForDetails"]),
    getSpeed5MinuteForPu(speedForPu) {
      const unit = this.activeKpiConfig?.product_unit;
      if (!unit) return speedForPu.product_speed;
      switch (getUnitType(unit)) {
        case "product_unit": {
          return speedForPu.product_speed;
        }
        case "weight_unit": {
          // speed was asked to be shown in weight but the current wlv speed is not in weight
          if (speedForPu.weight_length_volume_speed_unit !== "kg") return null;
          return convertKilogramTo(speedForPu.weight_length_volume_speed, unit);
        }
        case "length_unit": {
          // speed was asked to be shown in weight but the current wlv speed is not in weight
          if (speedForPu.weight_length_volume_speed_unit !== "m") return null;
          return convertMeterTo(speedForPu.weight_length_volume_speed, unit);
        }
        case "volume_unit": {
          // speed was asked to be shown in weight but the current wlv speed is not in weight
          if (speedForPu.weight_length_volume_speed_unit !== "l") return null;
          return convertLiterTo(speedForPu.weight_length_volume_speed, unit);
        }
        default:
          return null;
      }
    },
    toggleDetailsByIndex(puIdAndIndex) {
      if (this.detailsIndex === puIdAndIndex.index) {
        this.closeDetails();
      } else {
        this.detailsIndex = puIdAndIndex.index;
        this.clickedChildState = true;
        this.setActiveProductionUnitIdForDetails(puIdAndIndex.production_unit_id);
      }
    },
    closeDetails() {
      this.clearProductionUnitIdForDetails();
      this.clickedChildState = false;
      this.detailsIndex = -1;
    },
  },
  mounted() {
    let puIndex = -1;
    this.productionUnitsStateKpisAndAlert.find((pu, index) => {
      if (pu.puId === this.activeProductionUnitIdForDetails) {
        puIndex = index;
        return true;
      } else {
        return false;
      }
    });
    this.detailsIndex = puIndex;
  },
};
</script>

<style lang="scss" scoped>
.overviewTitle {
  padding-top: 20px;
}
.PU-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--grid-gutter);
  grid-auto-flow: row dense;
  padding-left: 7px; // spacer for shadow from elevation props
  padding-bottom: var(--grid-gutter);

  &.clicked {
    border: 2px solid red;
  }
  @media ($wx-sm-min) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(var(--grid-gutter) / 2);
  }

  @media ($wx-md-min) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ($wx-lg-min) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media ($wx-xl-min) {
    grid-template-columns: repeat(6, 1fr);
  }

  /**
  * Dimming the visibility of the unactive
  * siblings of a `pu-cards.details-active`
  * from PUItems.vue
  */
  article {
    opacity: 1;
    transition: var(--smooth-transition);
  }

  &.child-clicked {
    article:not(.disconnected) {
      // all cards that are not clicked
      &:not(.details-active) {
        opacity: var(--opacity-disabled-effect);
      }
      // Un mouse hover users can better read the unactivated card
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
</style>
